<template>
  <div class="stat-tab__wrapper">
    <div class="stat-tab">
      <div class="tab-string header-string">
        <div class="tab-cell first-col">Команды</div>
        <div class="tab-cell" v-for="a in 10" :key="a">
          <div class="team-label">
            <div class="logo">
              <img src="~@/img/test-logo.png" alt="">
            </div>
            <div class="name">DreamEaters</div>
          </div>
        </div>
      </div>
      <div class="tab-string">
        <div class="tab-cell first-col">Популярный герой</div>
        <div class="tab-cell" v-for="b in 10" :key="b">Герой</div>
      </div>
      <div class="tab-string">
        <div class="tab-cell first-col">Долгий матч</div>
        <div class="tab-cell" v-for="c in 10" :key="c">09:42:53</div>
      </div>
      <div class="tab-string">
        <div class="tab-cell first-col">Быстрый матч</div>
        <div class="tab-cell" v-for="d in 10" :key="d">00:42:53</div>
      </div>
      <div class="tab-string">
        <div class="tab-cell first-col">Убийств</div>
        <div class="tab-cell" v-for="e in 10" :key="e">120</div>
      </div>
      <div class="tab-string">
        <div class="tab-cell first-col">Умерших курьеров</div>
        <div class="tab-cell" v-for="f in 10" :key="f">120</div>
      </div>
      <div class="tab-string">
        <div class="tab-cell first-col">Установленных вардов</div>
        <div class="tab-cell" v-for="g in 10" :key="g">120</div>
      </div>
      <div class="tab-string">
        <div class="tab-cell first-col">Убитых рошанов</div>
        <div class="tab-cell" v-for="h in 10" :key="h">120</div>
      </div>
      <div class="tab-string">
        <div class="tab-cell first-col">Собранных рун</div>
        <div class="tab-cell" v-for="i in 10" :key="i">120</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tournamentStatisticsTab"
}
</script>

<style>

</style>
<template>
  <div class="container">
    <div class="statistics">
      <nav class="tabs-group">
        <ul>
          <li @click="activeTab = 'Players'" :class="[ activeTab === 'Players' ? 'active' : '' ]">Игроки</li>
          <li>Команды</li>
        </ul>
      </nav>
      <tournament-statistics-tab/>
      <!--
            <transition name="default">
              <div class="tab-content" v-if="activeTab === 'Players'">
                <div class="column main">
                  <div class="string head">Команды</div>
                  <div ref="s1" class="string s1">Популярный герой</div>
                  <div class="string s2">Долгий матч</div>
                  <div class="string s3">Быстрый матч</div>
                  <div class="string s4">Убийств</div>
                  <div class="string s5">Умерших курьеров</div>
                  <div class="string s6">Установленных вардов</div>
                  <div class="string s7">Убитых рошанов</div>
                  <div class="string s8">Собранных рун</div>
                </div>
                <div class="column" v-for="column in 10" :key="column">
                  <div class="string head">
                    <div class="team-label">
                      <div class="logo">
                        <img src="~@/img/test-logo.png" alt="">
                      </div>
                      <div class="name">DreamEaters</div>
                    </div>
                  </div>
                  <div class="string s1">Герой</div>
                  <div class="string s2">09:42:53</div>
                  <div class="string s3">00:42:53</div>
                  <div class="string s4">120</div>
                  <div class="string s5">120</div>
                  <div class="string s6">10</div>
                  <div class="string s7">200</div>
                  <div class="string s8">42</div>
                </div>
              </div>
            </transition>
      -->
    </div>
  </div>
</template>

<script>
import TournamentStatisticsTab from "@/components/tournaments/singleTournament/tournamentStatisticsTab";

export default {
  name: "tournamentStatistics",
  components: {TournamentStatisticsTab},
  data() {
    return {
      activeTab: 'Players',
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>